@import "./journey-builder-templates.scss";
@import "./welcome-mat.scss";
@import "../components/homepage-card/homepage-card.scss";
@import "../components/homepage-calendar/homepage-calendar.scss";

.mc-homepage {
	// Override lightning design system background
	background-color: #fafaf9;
	a.bg-link {
		color: #0b5cab;
	}
}

.einstein-learning-center {
	.slds-card__body--inner {
		height: 100%;
	}
	.einstein-learning-center_body {
		display: flex;
		flex-direction: column;
		align-items: center;
		height: 100%;
	}

	.einstein-learning-center_img-container {
		flex-grow: 1;
		position: relative;
		width: 100%;
	}

	.einstein-learning-center_img {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		margin: auto;
		max-height: 100%;
	}

	.einstein-learning-center_flag {
		text-transform: uppercase;
		position: absolute;
		display: inline-block;
		top: 12px;
		right: 0;
		padding: 0.1em 0.5em;
		background-color: #009edb;
		color: white;
	}

	.slds-scrollable--y {
		overflow-y: hidden;
	}
}
