@import "~slick-carousel/slick/slick.scss";
@import "./slick-theme.scss";

$header-awareness-color: #00396B;
$header-acquisition-color: #048149;
$header-onboarding-color: #944FBF;
$header-engagement-color: #0070D2;
$header-retention-color: #ED8B03;
$complexity-background-white: #FFFFFF;
$complexity-background-gray: #D8DDE6;
$complexity-background-blue: #54698D;
$complexity-text-blue: #00396B;
$complexity-text-black: #061C3F;
$complexity-text-white: #FFFFFF;
$bg-link-color: #0b5cab;

.journey-templates {
	position: relative;

	&-header {
		height: 40px;
		width: 100%;
		position: relative;

		&-title {
			h1 {
				margin: 3px 10px;
			}

			.slds-icon-custom-custom106,
			.slds-icon-custom-106 {
				background-color: #ffb75d;
			}
		}
	}

	&-loader {
		height: 300px;
	}

	&-content {
		width: 100%;
		position: relative;
	}

	&-card {
		background: white;
		cursor: pointer;
		border-radius: 0.75rem 0.75rem 10px 10px;
		height: 270px;
		margin: 15px 20px;
		position: relative;
		box-shadow: 0 2px 0 0 rgba(0,0,0,0.1);

		&:focus {
			outline: none;
		}

		&-header {
			padding-top: 16px;
			padding-left: 16px;
			padding-right: 16px;
			padding-bottom: 24px;
			overflow: hidden;
			border-radius: 10px 10px 0 0;
			background-color: #00396b;
			clear: both;

			&[data-lifecycle-color="awareness"] {
				background-color: $header-awareness-color;
			}
			&[data-lifecycle-color="acquisition"] {
				background-color: $header-acquisition-color;
			}
			&[data-lifecycle-color="onboarding"] {
				background-color: $header-onboarding-color;
			}
			&[data-lifecycle-color="engagement"] {
				background-color: $header-engagement-color;
			}
			&[data-lifecycle-color="retention"] {
				background-color: $header-retention-color;
				.journey-templates-card-header-lifecycle {
					color: $complexity-text-black;
				}
			}

			&-left {
				float: left;
				vertical-align: top;
			}

			&-lifecycle {
				text-align: left;
				height: 19px;
				font-size: 14px;
				margin-bottom: 6px;
				color: rgb(244, 246, 249);

				&-icon {
					margin: 0 auto;
					width: 36px;
					height: 37px;
					margin-bottom: 4px;
					background-repeat: no-repeat;
					background-size: contain;
					background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MiIgaGVpZ2h0PSI1MiIgdmlld0JveD0iMCAwIDUyIDUyIj48cGF0aCBmaWxsPSIjZmZmIiBkPSJtMjYgMmMtMTAuNSAwLTE5IDguNS0xOSAxOS4xIDAgMTMuMiAxMy42IDI1LjMgMTcuOCAyOC41IDAuNyAwLjYgMS43IDAuNiAyLjUgMCA0LjItMy4zIDE3LjctMTUuMyAxNy43LTI4LjUgMC0xMC42LTguNS0xOS4xLTE5LTE5LjF6IG0wIDI3Yy00LjQgMC04LTMuNi04LThzMy42LTggOC04IDggMy42IDggOC0zLjYgOC04IDh6Ij48L3BhdGg+PC9zdmc+');

					/* These are local copies of lightning SLDS icons */
					/* FIXME: remove these when SLDS becomes available in the app */
					&[data-lifecycle-icon="engagement"] {
						background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MiIgaGVpZ2h0PSI1MiIgdmlld0JveD0iMCAwIDUyIDUyIj48cGF0aCBmaWxsPSIjZmZmIiBkPSJtNDcuOCAzMWMtMC4xLTAuNC0wLjEtMC44IDAuMS0xLjIgMS4zLTIuMyAyLjEtNC45IDIuMS03LjcgMC04LjgtNy42LTE2LTE3LTE2LTQuNCAwLTguNCAxLjYtMTEuNCA0LjIgMTAuMyAxLjIgMTguNCA5LjYgMTguNCAxOS44IDAgMi41LTAuNSA0LjktMS40IDcuMSAxLjEtMC40IDIuMi0wLjkgMy4yLTEuNCAwLjQtMC4yIDAuOC0wLjMgMS4yLTAuMWw2LjEgMi40YzAuNiAwLjIgMS4xLTAuMyAwLjktMC45bC0yLjItNi4yeiBtLTI4LjgtMTYuOWMtOS40IDAtMTcgNy4yLTE3IDE2IDAgMi44IDAuOCA1LjQgMi4xIDcuNyAwLjIgMC40IDAuMyAwLjggMC4xIDEuMmwtMi4yIDYuMWMtMC4yIDAuNiAwLjMgMS4xIDAuOSAwLjlsNi4xLTIuNGMwLjQtMC4xIDAuOC0wLjEgMS4yIDAuMSAyLjYgMS41IDUuNiAyLjMgOC44IDIuMyA5LjQgMCAxNy03LjIgMTctMTYgMC04LjctNy42LTE1LjktMTctMTUuOXoiPjwvcGF0aD48L3N2Zz4=');
					}

					&[data-lifecycle-icon="awareness"] {
						background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MiIgaGVpZ2h0PSI1MiIgdmlld0JveD0iMCAwIDUyIDUyIj48ZyBmaWxsPSIjZmZmIj48cGF0aCBkPSJtMjIuNyA0NS40bC0xLjMtMWMtMS40LTEtMS40LTMtMS40LTR2LTIuOWMwLTAuOC0wLjctMS41LTEuNS0xLjVoLTZjLTAuOCAwLTEuNSAwLjctMS41IDEuNXY3LjdjMCAyLjcgMS42IDQuOCA0LjEgNC44aDQuOWMyLjkgMCAzLjEtMiAzLjEtMnMwLjUtMS44LTAuNC0yLjZ6IG0yMi4zLTI3LjR2LTEzLjdjMC0yLjQtMy0zLjEtNC42LTEuNWwtOC45IDguNGMtMS40IDEuMi0zLjIgMS43LTUgMS43aC0xNS4yYy01LjIgMC4xLTkuMyA0LjYtOS4zIDkuOHYwLjJjMCA1LjIgNC4xIDkuMSA5LjMgOS4xaDE1LjJjMS45IDAgMy43IDAuOCA1LjEgMmw4LjggOC42YzEuNiAxLjYgNC42IDEgNC42LTEuNHYtMTMuNmMzIDAgNC44LTIuMSA0LjgtNC44IDAtMi43LTEuOC00LjgtNC44LTQuOHoiPjwvcGF0aD48L2c+PC9zdmc+');
					}

					&[data-lifecycle-icon="acquisition"] {
						background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MiIgaGVpZ2h0PSI1MiIgdmlld0JveD0iMCAwIDUyIDUyIj48ZyBmaWxsPSIjZmZmIj48cGF0aCBkPSJtMjEuOSAzN2MwLTIuNyAwLjktNS44IDIuMy04LjIgMS43LTMgMy42LTQuMiA1LjEtNi40IDIuNS0zLjcgMy05IDEuNC0xMy0xLjYtNC4xLTUuNC02LjUtOS44LTYuNHMtOCAyLjgtOS40IDYuOWMtMS42IDQuNS0wLjkgOS45IDIuNyAxMy4zIDEuNSAxLjQgMi45IDMuNiAyLjEgNS43LTAuNyAyLTMuMSAyLjktNC44IDMuNy0zLjkgMS43LTguNiA0LjEtOS40IDguNy0wLjggMy44IDEuOCA3LjcgNS45IDcuN2gxN2MwLjggMCAxLjMtMSAwLjgtMS42LTIuNS0yLjktMy45LTYuNi0zLjktMTAuNHogbTE2LTEyYy02LjYgMC0xMiA1LjQtMTIgMTJzNS40IDEyIDEyIDEyIDEyLTUuNCAxMi0xMi01LjQtMTItMTItMTJ6IG02LjEgMTNjMCAwLjYtMC41IDEtMS4xIDFoLTIuOXYzYzAgMC42LTAuNSAxLTEuMSAxaC0yYy0wLjYgMC0wLjktMC40LTAuOS0xdi0zaC0zLjFjLTAuNiAwLTAuOS0wLjQtMC45LTF2LTJjMC0wLjYgMC4zLTEgMC45LTFoMy4xdi0zYzAtMC42IDAuMy0xIDAuOS0xaDJjMC42IDAgMS4xIDAuNCAxLjEgMXYzaDIuOWMwLjYgMCAxLjEgMC40IDEuMSAxdjJ6Ij48L3BhdGg+PC9nPjwvc3ZnPg==');
					}

					&[data-lifecycle-icon="onboarding"] {
						background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MiIgaGVpZ2h0PSI1MiIgdmlld0JveD0iMCAwIDUyIDUyIj48ZyBmaWxsPSIjZmZmIj48cGF0aCBkPSJtMTEuNSAyNC45YzAtMC40LTAuNS0wLjYtMC44LTAuNGwtNC41IDMuN2MtMC4yIDAuMi0wLjIgMC41LTAuMiAwLjh2MTUuOWMwIDEgMS40IDEuNSAyLjMgMC44bDcuMy01LjdjMC4yLTAuMiAwLjMtMC40IDAuMS0wLjctMS44LTIuNy0zLjYtNy4yLTQuMi0xNC40eiBtOS43IDE0LjljMC4yIDAuMSAwLjQgMC4yIDAuNiAwLjJoOC4zYzAuMiAwIDAuNC0wLjEgMC42LTAuMiAxLjItMC44IDYtNC44IDYtMTguNCAwLTYuMy0xLjgtMTAuNy0zLjktMTMuNy0zLjEtNC40LTYuOC01LjctNi44LTUuN3MtMy44IDEuMy02LjkgNS44Yy0yLjEgMy0zLjggNy4zLTMuOCAxMy42IDAgMTMuNiA0LjcgMTcuNiA1LjkgMTguNHogbTQuNy0yNi44YzMuMyAwIDYgMi43IDYgNnMtMi43IDYtNiA2LTYtMi43LTYtNiAyLjctNiA2LTZ6IG0xOS44IDE1LjJsLTQuNC0zLjdjLTAuMy0wLjMtMC44LTAuMS0wLjggMC40LTAuNiA3LjItMi40IDExLjctNC4xIDE0LjUtMC4xIDAuMi0wLjEgMC41IDAuMSAwLjdsNy4zIDUuN2MwLjkgMC43IDIuMiAwLjIgMi4yLTAuOHYtMTZjMC0wLjMgMC0wLjYtMC4zLTAuOHogbS0xMy41IDE2LjNjLTAuMi0wLjMtMC41LTAuNS0wLjgtMC41aC0xMWMtMC4zIDAtMC43IDAuMi0wLjggMC41LTAuNCAwLjYtMSAxLjctMS40IDMuMS0wLjMgMS4yIDAuNyAyLjQgMiAyLjRoMTEuNGMxLjMgMCAyLjMtMS4yIDItMi40LTAuNC0xLjUtMS0yLjYtMS40LTMuMXoiPjwvcGF0aD48L2c+PC9zdmc+');
					}

					&[data-lifecycle-icon="retention"] {
						background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MiIgaGVpZ2h0PSI1MiIgdmlld0JveD0iMCAwIDUyIDUyIj48ZyBmaWxsPSIjZmZmIj48cGF0aCBkPSJtNTEuNiAyOC44bC0yLjEtMi4xYy0wLjYtMC42LTEuNS0wLjYtMi4xIDBsLTIuNyAyLjdjLTAuNyAwLjctMS43IDAuMi0xLjctMC43di0xNi43YzAtMi4yLTEuOC00LTQtNGgtMTQuNWMtMC44IDAtMS41IDAuNy0xLjUgMS41djNjMCAwLjggMC43IDEuNSAxLjUgMS41aDExYzAuOCAwIDEuNSAwLjcgMS41IDEuNXYxMy4yYzAgMC45LTEuMSAxLjMtMS44IDAuN2wtMi42LTIuNmMtMC42LTAuNi0xLjYtMC42LTIuMSAwbC0yLjEgMi4yYy0wLjYgMC42LTAuNiAxLjUgMCAyLjFsMTAuNSAxMC41YzAuNiAwLjYgMS41IDAuNiAyLjEgMGwxMC42LTEwLjZjMC41LTAuNiAwLjUtMS42IDAtMi4yeiBtLTI0LjEgOS4yaC0xMWMtMC44IDAtMS41LTAuNy0xLjUtMS41di0xMy4yYzAtMC45IDEuMS0xLjMgMS44LTAuN2wyLjYgMi42YzAuNiAwLjYgMS42IDAuNiAyLjEgMGwyLjEtMi4xYzAuNi0wLjYgMC42LTEuNSAwLTIuMWwtMTAuNC0xMC42Yy0wLjYtMC42LTEuNS0wLjYtMi4xIDBsLTEwLjcgMTAuNmMtMC42IDAuNi0wLjYgMS41IDAgMi4xbDIuMSAyLjFjMC42IDAuNiAxLjUgMC42IDIuMSAwbDIuNy0yLjdjMC42LTAuNiAxLjctMC4yIDEuNyAwLjd2MTYuOGMwIDIuMiAxLjkgNCA0LjEgNGgxNC41YzAuOCAwIDEuNS0wLjcgMS41LTEuNXYtM2MtMC4xLTAuOC0wLjgtMS41LTEuNi0xLjV6Ij48L3BhdGg+PC9nPjwvc3ZnPg==');
					}

					&[data-lifecycle-icon="advocacy"] {
						background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MiIgaGVpZ2h0PSI1MiIgdmlld0JveD0iMCAwIDUyIDUyIj48ZyBmaWxsPSIjZmZmIj48cGF0aCBkPSJtMTAuNSAyMWgtNWMtMC44IDAtMS41IDAuNy0xLjUgMS41djIzYzAgMC44IDAuNyAxLjUgMS41IDEuNWgyLjVjMi4yIDAgNC0xLjggNC00di0yMC41YzAtMC44LTAuNy0xLjUtMS41LTEuNXogbTMzLjUgMWgtNmMtMi4yIDAtNC0xLjgtNC00di0xMGMwLTIuMi0xLjgtNC00LTRoLTIuNWMtMC44IDAtMS41IDAuNy0xLjUgMS41djZjMCA1LjMtMy43IDEwLjUtOC41IDEwLjUtMC44IDAtMS41IDAuNy0xLjUgMS41djIwYzAgMC44IDAuNiAxLjUgMS40IDEuNSA2LjggMC4zIDkuMSAzIDE2LjIgMyA3LjUgMCAxNC40LTAuOCAxNC40LTkuNXYtMTIuNWMwLTIuMi0xLjgtNC00LTR6Ij48L3BhdGg+PC9nPjwvc3ZnPg==');
					}
				}
			}
		}

		&-ribbon {
			position: absolute;
			top: 92px;
			left: -6px;
			width: 156px;
			height: 26px;
			line-height: 26px;
			font-size: 11px;
			text-transform: uppercase;
			text-indent: 0.75rem;
			text-align: left;
			color: #fff;
			background-color: #54698d;
			box-shadow: 0 1px 2px 0 rgba(84,105,141,0.75);
			border-radius: 3px 0 0 3px;
			padding-top: 1px;
			text-transform: uppercase;

			&:after {
				content: "";
				position: absolute;
				right: -6px;
				display: block;
				bottom: 0;
				border: 13px solid #54698d;
				border-right-width: 6px;
				border-left-width: 0;
				border-right-color: transparent;
			}

			&[data-complexity="simple"] {
				color: $complexity-text-blue;
				background-color: $complexity-background-white;

				&:after {
					border-color: $complexity-background-white;
					border-right-color: transparent;
				}
			}

			&[data-complexity="intermediate"] {
				color: $complexity-text-black;
				background-color: $complexity-background-gray;

				&:after {
					border-color: $complexity-background-gray;
					border-right-color: transparent;
				}
			}

			&[data-complexity="advanced"] {
				color: $complexity-text-white;
				background-color: $complexity-background-blue;

				&:after {
					border-color: $complexity-background-blue;
					border-right-color: transparent;
				}
			}
		}

		&-content {
			font-size: 14px;
			line-height: 17px;
			padding: 15px 16px 0 15px;
			height: 126px;
			overflow-y: auto;

			&-name {
				display: block;
				font-size: 18px;
				margin: 5px 0 5px 0;
				line-height: 22px;
				font-weight: bold;
				color: #0070d2;
				word-break: break-word;
			}

			&-description {
				font-weight: 300;
				line-height: 18px;
				word-break: break-word;
			}
		}

		&-footer {
			width: 100%;
			padding-right: 16px;
			margin-top: 10px;
			position: relative;
			height: 30px;

			.tile-tag {
				padding: 3px 8px 0 8px;
				margin: 0 3px 0 3px;
				height: 20px;
				font-size: 10px;
				background-color: #d8dde6;
				text-align: center;
				max-width: 120px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: pre;
				border-radius: 10px;

				&--view-more {
					color: #2a426c;
					font-weight: bold;
					font-size: 18px;
					line-height: 5px;
				}
			}
		}
	}

	.slick-slider {
		height: 350px;
	}

	.slick-slide {
		&:focus {
			outline: none;
		}
	}

	.slick-dots {
		height: 40px;
		bottom: 0;

		li {
			width: 16px;
			height: 16px;
			border: 1px solid #00396b;
			background-color: #ffffff;
			border-radius: 8px;
			opacity: 1;

			&.slick-active {
				background-color: $bg-link-color;
			}

			button {
				&:before {
					font-size: 0;
				}
			}
		}
	}

	.slick-next {
		color: $bg-link-color;
		opacity: 1;
		right: 20px;
		display: inline-block;
		bottom: 10px;
		top: inherit;
		z-index: 100;
		width:22px;
		height:22px;
		background-color: #ffffff;
		border-radius: 11px;
		border: 1px solid gray;

		&.slick-disabled {
			opacity: 1;
			color: $bg-link-color;
		}

		&:before {
			color: #00396b;
			border-style: solid;
			border-width: 0.1em 0.1em 0 0;
			content: '';
			display: inline-block;
			height: 0.45em;
			position: relative;
			top: 0em;
			vertical-align: top;
			width: 0.45em;
			left: -2px;
			transform: rotate(45deg);
		}
	}

	.slick-prev {
		color: $bg-link-color;
		opacity: 1;
		left: 20px;
		display: inline-block;
		bottom: 10px;
		top: inherit;
		z-index: 100;
		width:22px;
		height:22px;
		background-color: #ffffff;
		border-radius: 11px;
		border: 1px solid gray;

		&.slick-disabled {
			opacity: 1;
			color: $bg-link-color;
		}

		&:before {
			color: #00396b;
			border-style: solid;
			border-width: 0.1em 0.1em 0 0;
			content: '';
			display: inline-block;
			height: 0.45em;
			position: relative;
			vertical-align: top;
			width: 0.45em;
			left: 1px;
			transform: rotate(-135deg);
		}
	}
}
